.carousel__container {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 2rem;
  margin-bottom: -4.5vh; /* border-top: var(--blue-colour) 3px solid; */
}

.carousel__container .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.carousel__container:hover {
  cursor: pointer;
}

.carousel__container:hover .tooltiptext {
  visibility: visible;
}

.carousel__inner {
  display: flex;
  animation: scrollAnimation 150s linear infinite; /* Adjust the duration for the scroll speed */
}

.carousel__item {
  flex: 0 0 calc(80% / 6 - 10px); /* Display 6 items at a time with spacing between logos */
  margin-right: 100px; /* Adjust the spacing between logos */
  overflow: hidden;
  border-radius: 0.5rem;
  align-items: center;
  padding: 0px;
  display: flex;
  border-radius: 20px;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.carousel__item img {
  width: 100%;
  height: auto;
  object-fit: contain; /* Maintain aspect ratio and fit the logo within the container */
  user-select: none; /* Disable user selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
  cursor: default;
}

.where-we-worked {
  text-align: center;
  font-size: 90px;
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-100% * 16)
    ); /* Adjust the percentage for the number of logos displayed */
  }
}

@media screen and (max-width: 1440px) {
  .carousel__container {
    margin-bottom: -2.2vh;
  }
  .carousel__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 5 - 10px);
    margin-right: 80px;
    overflow: hidden;
    border-radius: 0.5rem;
    align-items: center;
    padding: 0px;
    display: flex;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 980px) {
  .where-we-worked {
    font-size: 60px;
  }
}

@media screen and (max-width: 600px) {
  .carousel__container {
    flex-basis: calc(100% / 3 - 10px);
  }
}
