/* Footer styles */
.footer {
  background-color: rgba(0, 184, 229, 0.8);
  padding: 20px 0;
  text-align: center;
}

.logo {
  background-color: white;
  padding: 50px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 7rem;
  border: 3px solid var(--pink-colour);
}

.logo img {
  width: 200px; /* Adjust the width as needed */
  height: auto;
}

.quick {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 5rem;
  font-size: 60px;
  color: white;
}
.footer-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  display: center;
  gap: 40px;
  font-size: 25px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 10%;
  padding-right: 10%;
}

.footer-links a {
  text-decoration: none;
  color: white;
  gap: 20px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  font-size: 35px;
  padding: 10px;
  fill: white;
}

.icons a {
  font-size: 35px;
  color: rgb(0, 0, 0);
}

.icons a:hover {
  fill: var(--pink-colour);
}

/* .line {
  background-color: rgb(255, 255, 255);
  height: 3px;
  width: 100%;
  margin: 20px 0;
} */

.copyright {
  font-size: 20px;
  padding: 10px;
  color: white;
}

.designer {
  font-size: 15px;
  color: white;
}

.GH {
  font-style: italic;
  font-size: 15px;
  color: white;
}

.GH:hover {
  text-decoration: underline;
  cursor: pointer;
}

svg {
  color: white;
}
