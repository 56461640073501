.school-work p {
  text-align: center;
  font-size: 40px;
  letter-spacing: 0px;
  margin: 0 15%;
}

.school-purple {
  background-color: var(--purplelight-colour);
}

.buttons-school {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.school-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 50%;
  height: 50%;
  object-fit: scale-down;
}

/* #make-smaller-btn-coaching {
  padding: 25px 150px;
} */

.italicParent {
  font-style: italic;
}

@media screen and (max-width: 1024px) {
  .image {
    width: 75%;
    height: 100%;
  }

  .speech-bubble-text-talk-student-coaching {
    font-size: 40px;
    top: 37%;
    left: 48%;
  }
}

@media screen and (max-width: 768px) {
  .about-us p {
    font-size: 20px;
  }
}
