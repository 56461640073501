#parent-talk-section {
  background-color: var(--purplelight-colour);
}

.talk-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.active {
  border: 5px solid var(--pink-colour);
}

.h1-parenttalk {
  margin-top: 6rem;
}

.bar {
  background-color: white;
  margin-left: -100px;
  margin-right: -100px;
}

.italic {
  font-style: italic;
}

.star {
  color: var(--pink-colour);
}

/* .example-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
} */

.down-arrow {
  margin-left: 1rem;
  font-size: 3rem;
  color: white;
  vertical-align: middle;
}

/* Media queries */

@media screen and (max-width: 1024px) {
  #school-staff-header p {
    font-size: 35px;
  }

  #make-smaller-btn {
    font-size: 32px;
    background-color: rgba(47, 184, 229, 0.8);
    color: rgb(255, 255, 255);
    padding: 20px 20px;
    border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%;
    margin: 15px;
    font-family: "Aptos", sans-serif;
    letter-spacing: 0px;
    width: fit-content;
    height: fit-content;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  }

  .talk-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -100px;
    margin-right: -100px;
  }

  .h1-parenttalk {
    margin-top: 3rem;
  }

  .parent-purple {
    padding: 50px 50px;
  }
}
