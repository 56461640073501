.ParentWorkHome__image {
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.ParentWorkHome {
  display: flex;
  justify-content: center;
}

.hidden {
  opacity: 0;
  transition: opacity 2s;
}

.show {
  opacity: 1;
}

#button-parent {
  position: absolute; /* Position the button absolutely */
  z-index: 1; /* Ensure button is above the image */
  font-size: 1.5vw; /* Responsive font size */
  background-color: rgba(47, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 1vw 1vw; /* Responsive padding */
  /* border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%; */
  border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
  margin: 2vh 2vw; /* Responsive margin */
  font-family: "Aptos", sans-serif;
  letter-spacing: 0.2vw; /* Responsive letter spacing */
  width: fit-content;
  height: fit-content;
  text-shadow: 0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.2); /* Responsive shadow */
  box-shadow: 0.2vw 0.4vw 0.6vw rgba(0, 0, 0, 0.3); /* Responsive shadow */
  position: absolute;
  bottom: 12vw; /* Adjust bottom spacing */
  right: 27%; /* Adjust right spacing */
}

#button-parent:hover {
  background-color: rgba(47, 184, 229, 1);
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  #button-parent {
    font-size: 1.5vw; /* Responsive font size */
    background-color: rgba(47, 184, 229, 0.8);
    color: rgb(255, 255, 255);
    padding: 1vw 1vw; /* Responsive padding */
    /* border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%; */
    border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
    margin: 2vh 2vw; /* Responsive margin */
    font-family: "Aptos", sans-serif;
    letter-spacing: 0.2vw; /* Responsive letter spacing */
    width: fit-content;
    height: fit-content;
    text-shadow: 0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.2); /* Responsive shadow */
    box-shadow: 0.2vw 0.4vw 0.6vw rgba(0, 0, 0, 0.3); /* Responsive shadow */
    position: absolute;
    bottom: 6vw; /* Adjust bottom spacing */
    right: 27%; /* Adjust right spacing */
  }

  #button-parent:hover {
    font-size: larger;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  #button-parent {
    position: absolute; /* Position the button absolutely */
    bottom: 6.5vh; /* Adjust bottom spacing as needed */
    right: 26%; /* Adjust right spacing as needed */
    z-index: 1; /* Ensure button is above the image */
    font-size: 1vh;
  }
}

@media screen and (max-width: 910px) {
  #button-parent {
    position: absolute; /* Position the button absolutely */
    bottom: 6vh; /* Adjust bottom spacing as needed */
    right: 27%; /* Adjust right spacing as needed */
    z-index: 1; /* Ensure button is above the image */
    font-size: 12px;
  }
}
@media screen and (width: 600px) {
  #button-parent {
    position: absolute; /* Position the button absolutely */
    bottom: 6vh; /* Adjust bottom spacing as needed */
    right: 27%; /* Adjust right spacing as needed */
    z-index: 1; /* Ensure button is above the image */
    font-size: 10px;
  }
}
