/* Navbar.css */

/* Navbar Container */
.navbar {
  background-color: white;
  padding: 0 20px; /* Reduce the padding for smaller screens */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  margin-bottom: 20px;
}

/* Top Half */
.navbar__top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navbar__left img,
.navbar__right img {
  /* object-fit: contain; */
  /* height: 70px; */
  /* margin-right: 10px; */
  margin-top: 15px;
  height: 5vw;
}

.navbar__center {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar__item {
  font-size: 70px; /* Reduce font size for smaller screens */
  text-transform: uppercase;
  font-weight: 900;
  margin-top: 10px; /* Adjust for smaller screens */
  vertical-align: middle; /* Center vertically */
}

.navbar__item Link {
  color: #a56eb0;
}

.navbar__item span {
  display: block;
}

/* Apply hover styles to all list items except .navbar__subtitle */
.nav__links li:hover {
  background-color: rgba(0, 184, 229, 0.8);
  color: white;
  transition: ease-in-out 0.25s;
  cursor: pointer;
}

/* Bottom Half */
.navbar__bottom {
  display: flex;
  justify-content: space-around;
  width: 100%;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  font-family: var(--font-aptos);
}

.navbar__link {
  text-decoration: none;
  color: var(--purple-colour);
  font-family: "Aptos", sans-serif;
  letter-spacing: 3px;
  font-size: 5.6vw;
}

.navbar__link__btn {
  text-decoration: none;
  font-size: 40px; /* Reduce font size for smaller screens */
  color: var(--grey-colour);
  padding: 0px 0px; /* Reduce padding for smaller screens */
}

.navbar__link__btn {
  text-decoration: none;
  font-size: 20px;
  background-color: var(--blue-colour);
  color: white;
  border-radius: 20px;
  transition: ease-in 0.3s;
  padding: 10px 20px;
}

a .btn-nav {
  color: white;
}

/* Buttons */

.btn-nav {
  font-size: 20px;
  background-color: rgba(0, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 15px 30px;
  /* border-radius: 62% 38% 71% 29% / 26% 46% 54% 74%; */
  border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
  /* border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%; */
  margin: 10px;
  font-family: "Aptos", sans-serif;
  letter-spacing: 0.5px;
  width: fit-content;
  height: fit-content;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.btn-nav:hover {
  background-color: var(--blue-colour);
  color: white;
  transition: ease-in-out 0.5s;
  padding: 16px 31px;
  cursor: pointer;
  font-size: 21px;
}

/* Dropdown Styles */

.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--blue-colour);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 20px;
  margin-top: 1px;
}

.dropdown-content a {
  padding: 8px 16px;
  display: block;
  text-decoration: none;
  color: #ffffff;
  font-size: 23px;
  margin-bottom: 0.25rem;
}

.dropdown-content a:last-child {
  border-bottom: none;
}

.dropdown-content a:hover {
  background-color: #ffffff;
  font-size: 24px;
  transition: ease-in-out 0.25s;
  border-radius: 20px;
  color: #2fb8e5;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.close-icon {
  color: var(--purple-colour);
  font-size: 70px;
}

/* LARGER IPADS */
/* Media Queries */

@media screen and (min-width: 1960px) {
  .navbar__top {
    width: 75%;
  }
}

@media screen and (min-width: 1231px) {
  .navbar__bottom {
    padding: 10px;
  }
}

@media screen and (max-width: 1230px) {
  .navbar__item {
    font-size: 60px;
  }

  .navbar {
    padding: 0px 10px;
  }

  .navbar__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .navbar__link {
    font-size: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* 
    .navbar__left img {
      height: 120px;
    } */

  .navbar__center {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* margin-right: 146px; */
  }

  .hamburger-icon {
    font-size: 60px;
    cursor: pointer;
    margin-right: 50px;
    margin-left: 50px;
  }

  .close-icon {
    font-size: 71px;
  }

  .mobile-dropdown {
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: var(
      --purple-colour
    ); /* Set your desired background color */
    z-index: 1000; /* Adjust z-index as needed */
    width: 50%; /* Set your desired width */
    overflow-y: auto;
    margin-top: 177px;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    font-family: "Aptos", sans-serif;
    transition: 0.6s cubic-bezier(0.62, 0.04, 0.3, 1.56);
  }

  .mobile-dropdown.open {
    transform: translateX(0%);
  }

  .nav__links li {
    border-bottom: #2fb8e5 3px solid;
    border-radius: 10px;
    padding: 10px 30px;
    margin: 10px;
    font-size: 50px;
    letter-spacing: 2.5px;
    color: black;
  }

  .nav__links li:hover {
    background-color: #2fb8e5;
    transition: ease-in-out 0.25s;
    cursor: pointer;
  }

  .dropdown-image {
    height: 300px;
    width: fit-content;
    margin-top: 40px !important;
    margin: auto;
    border-radius: 10px;
    border: #2fb8e5 3px solid;
  }

  .nav__link-content {
    color: white;
  }
}

@media screen and (max-width: 1028px) and (max-height: 800px) {
  .navbar__subtitle {
    display: none; /* Hide the .navbar__subtitle element */
  }

  .mobile-dropdown {
    margin-top: 111px;
  }
}

/* MOBILE AND SMALL IPADS (IPHONE 14) */
@media screen and (max-width: 1228px) {
  .navbar__item {
    font-size: 60px;
    padding-top: 0.5rem;
    font-weight: 100;
  }

  .navbar {
    padding: 10px 10px;
  }

  .navbar__link {
    font-size: 4.5rem;
    line-height: 1;
    letter-spacing: 0.5px;
    font-weight: 500;
  }

  .navbar__left img {
    height: 85px;
    margin-right: -20px;
  }

  .navbar__center {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-right: 0px;
  }

  .navbar__subtitle {
    font-size: 20px;
    width: 338px;
    height: 43px;
    padding: 9px 20px;
  }

  .hamburger-icon {
    font-size: 90px;
    cursor: pointer;
    margin-right: 50px;
    margin-left: 50px;
  }

  .close-icon {
    font-size: 90px;
  }

  .navbar__right img {
    display: none;
  }

  .navbar__bottom {
    display: none;
  }

  .mobile-dropdown {
    border-radius: 0px;
    margin-top: 224px;
  }

  .mobile-dropdown {
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    height: 80vh;
    background-color: var(
      --purple-colour
    ); /* Set your desired background color */
    z-index: 1000; /* Adjust z-index as needed */
    width: 100%; /* Set your desired width */
    overflow-y: auto;
    margin-top: 177px;
    text-align: center;
    justify-content: center;
    border-radius: 0px;
    font-family: "Aptos", sans-serif;
    transition: 0.6s cubic-bezier(0.62, 0.04, 0.3, 1.56);
  }

  .nav__links li {
    border-bottom: #2fb8e5 3px solid;
    border-radius: 10px;
    padding: 40px 30px;
    margin: 10px;
    font-size: 7vw;
    letter-spacing: 2.5px;
    color: black;
    padding-bottom: 40px;
  }

  .nav__links li:hover {
    background-color: #2fb8e5;
    transition: ease-in-out 0.25s;
    cursor: pointer;
  }
}

/* VERY SMALL MOBILES */
@media screen and (max-width: 814px) {
  .navbar__item {
    font-size: 35px;
  }

  .navbar__link {
    font-size: 3rem;
    line-height: 1;
    letter-spacing: 0.5px;
  }

  .navbar__subtitle {
    font-size: 18px;
    width: 330px;
    height: 36px;
    padding: 6px 20px;
  }

  .navbar__left img {
    height: 50px;
  }

  .close-icon {
    font-size: 70px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .hamburger-icon {
    font-size: 80px;
  }

  .mobile-dropdown {
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: var(
      --purple-colour
    ); /* Set your desired background color */
    z-index: 1000; /* Adjust z-index as needed */
    width: 100%; /* Set your desired width */
    overflow-y: auto; /* Enable vertical scrolling if needed */
    margin-top: 235px;
    text-align: center;
    justify-content: center;
    border-radius: 0px;
    font-family: "Aptos", sans-serif;
    transition: 0.6s cubic-bezier(0.62, 0.04, 0.3, 1.56);
  }

  .mobile-dropdown.open {
    transform: translateX(0%);
  }

  .nav__links li {
    border-bottom: #2fb8e5 3px solid;
    border-radius: 10px;
    padding: 10px 30px;
    margin: 10px;
    font-size: 50px;
    letter-spacing: 2.5px;
    color: black;
    padding-bottom: 40px;
  }

  .nav__links li:hover {
    background-color: #2fb8e5;
    transition: ease-in-out 0.25s;
    cursor: pointer;
  }

  .nav__link-content {
    color: white;
  }
}

@media screen and (max-width: 450px) {
  .navbar__item {
    font-size: 20px;
  }

  .navbar__link {
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.5px;
  }

  .navbar__subtitle {
    font-size: 14px;
    width: 330px;
    height: 36px;
    padding: 6px 20px;
  }

  .navbar__left img {
    height: 50px;
  }

  .close-icon {
    font-size: 70px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .hamburger-icon {
    font-size: 60px;
  }

  .mobile-dropdown {
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: var(
      --purple-colour
    ); /* Set your desired background color */
    z-index: 1000; /* Adjust z-index as needed */
    width: 100%; /* Set your desired width */
    overflow-y: auto; /* Enable vertical scrolling if needed */
    margin-top: 235px;
    text-align: center;
    justify-content: center;
    border-radius: 0px;
    font-family: "Aptos", sans-serif;
    transition: 0.6s cubic-bezier(0.62, 0.04, 0.3, 1.56);
  }

  .mobile-dropdown.open {
    transform: translateX(0%);
  }

  .nav__links li {
    border-bottom: #2fb8e5 3px solid;
    border-radius: 10px;
    padding: 10px 30px;
    margin: 10px;
    font-size: 50px;
    letter-spacing: 2.5px;
    color: black;
    padding-bottom: 40px;
  }

  .nav__links li:hover {
    background-color: #2fb8e5;
    transition: ease-in-out 0.25s;
    cursor: pointer;
  }

  .nav__link-content {
    color: white;
  }
}
