.book__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: -13%;
  margin-bottom: -6%;
}

/* Left side content */
.book__left {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4%;
}

/* Header style */
.BookHomePage__header {
  font-size: 4.8vw;
  color: var(--grey-colour);
  font-weight: 400;
  font-family: var(--font-aptos);
  /* margin-bottom: 7rem; */
}

/* Text style */
.BookHomePage__text {
  font-size: 2.3vw;
  color: var(--purple-colour);
  font-weight: 100;
  line-height: 1.4;
  font-family: "aptos-light", sans-serif;
  text-align: center !important; /* Resets to center */
  text-align-last: initial !important; /* Resets to default value */
  margin: 0 !important; /* Overrides the margin */
}

/* Read More button */
#readMoreButton {
  color: var(--blue-colour);
  text-decoration: underline;
  font-size: smaller;
  margin-left: 16px;
}

/* Right side image and Buy Now button */
.book__right {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Image style */
.book__right img {
  max-width: 65%;
  height: auto;
}

/* Buy Now button style */
.buy-now-button {
  margin-top: 20px;
}

#buy-now {
  display: flex;
  z-index: 1; /* Ensure button is above the image */
  font-size: 1.5vw; /* Responsive font size */
  background-color: rgba(47, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 1vw 1vw; /* Responsive padding */
  /* border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%; */
  border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
  margin: 2vh 2vw; /* Responsive margin */
  font-family: "Aptos", sans-serif;
  letter-spacing: 0.2vw; /* Responsive letter spacing */
  width: fit-content;
  height: fit-content;
  text-shadow: 0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.2); /* Responsive shadow */
  box-shadow: 0.2vw 0.4vw 0.6vw rgba(0, 0, 0, 0.3); /* Responsive shadow */
  margin-top: -4vh;
}

#buy-now:hover {
  background-color: rgba(47, 184, 229, 1);
  cursor: pointer;
}

.hidden {
  opacity: 0;
  transition: opacity 3s;
}

.show {
  opacity: 1;
}
/* Media queries */

@media screen and (max-width: 1800px) {
  .book__container {
    flex-direction: column;
  }

  .BookHomePage__header {
    margin-bottom: 3rem;
    font-size: 4.5vw;
    font-weight: 350;
    margin-top: 10%;
  }

  #BookPage__Container {
    margin-top: 10%;
  }

  .book__left {
    padding: 3%;
  }

  /* .BookHomePage__text {
    font-size: 1.5vw;
    margin-bottom: 4rem;
    font-family: "aptos-light", sans-serif;
  } */

  .book__right {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1440px) {
  .book__container {
    flex-direction: column;
  }

  .BookHomePage__header {
    font-weight: 400;
  }

  .book__left {
    margin-bottom: 20px;
  }

  .book__right {
    margin-top: 1vh;
  }

  .book__right img {
    max-width: 65%;
    height: auto;
  }

  #readMoreButton {
    color: var(--blue-colour);
    text-decoration: underline;
  }

  #buy-now {
    z-index: 1; /* Ensure button is above the image */
    font-size: 2.2vw; /* Responsive font size */
    background-color: rgba(47, 184, 229, 0.8);
    color: rgb(255, 255, 255);
    padding: 1vw 1vw; /* Responsive padding */
    /* border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%; */
    border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
    margin: 2vh 2vw; /* Responsive margin */
    font-family: "Aptos", sans-serif;
    letter-spacing: 0.2vw; /* Responsive letter spacing */
    width: fit-content;
    height: fit-content;
    text-shadow: 0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.2); /* Responsive shadow */
    box-shadow: 0.2vw 0.4vw 0.6vw rgba(0, 0, 0, 0.3); /* Responsive shadow */
  }

  #buy-now:hover {
    background-color: rgba(47, 184, 229, 1);
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .book__container {
    flex-direction: column;
  }

  .BookHomePage__header {
    margin-bottom: 2rem;
    font-size: 46px;
  }

  .book__left {
    margin-bottom: -5vh;
    padding: 3%;
  }

  .book__right {
    margin-top: 5vh;
  }

  /* .BookHomePage__text {
    font-size: 2.55vw;
    letter-spacing: 1px;
  } */

  .book__right img {
    max-width: 100%;
    height: auto;
  }
  .book__right img {
    max-width: 100%;
    height: auto;
    position: relative;
    border-radius: 20px;
    padding: 6px;
    padding-top: 21px;
  }

  .book__right-border {
    position: relative;
    width: 700px;
    height: 700px;
    background-color: rgba(121, 117, 117, 0.2);
    background-color: rgb(250, 250, 250);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 20px;
  }

  .book__right-border::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 140%;
    background: linear-gradient(var(--blue-colour), var(--pink-colour));
    animation: animate 5s linear infinite;
  }

  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@media screen and (max-width: 980px) {
  .BookHomePage__text {
    font-size: 2.55vw;
    letter-spacing: 1px;
  }
}

/* OR use this  */
