.school-work p {
  text-align: justify;
  text-align-last: center; /* Align last line to center */
  margin: 0 10%;
  font-size: 25px;
  letter-spacing: 0px;
}

.school-purple {
  background-color: var(--purplelight-colour);
}

.buttons-school {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.school-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.image {
  width: 35%;
  height: 50%;
  object-fit: scale-down;
} */

/* #make-smaller-btn-coaching {
  padding: 25px 100px;
} */

/* .quote-icon {
  display: none;
} */

.quote-icon-right {
  display: none;
}

@media screen and (max-width: 1024px) {
  .image {
    width: 75%;
    height: 100%;
  }

  #make-smaller-btn-coaching {
    padding: 25px 100px;
    font-size: 25px;
    font-weight: bolder;
  }
}

@media screen and (max-width: 768px) {
  .about-us p {
    font-size: 20px;
  }
}
