.header {
  color: rgb(255, 255, 255); /* Set your desired text color */
  position: relative;
}

.header__container {
  display: flex;
  justify-content: center;
}

.header__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
  position: relative; /* Position relative for absolute positioning of button */
}

.header__image {
  position: relative; /* Position relative for absolute positioning of button */
}

.header__image img {
  width: 100%;
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  height: auto;
  margin-right: 0px;
}

.phone-image {
  display: none;
}

/* Show the main image by default */
.main-image {
  display: block;
}

#about-btn {
  position: absolute; /* Position the button absolutely */
  z-index: 1; /* Ensure button is above the image */
  font-size: 1.5vw; /* Responsive font size */
  background-color: rgba(47, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 1vw 1vw; /* Responsive padding */
  /* border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%; */
  border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
  margin: 2vh 2vw; /* Responsive margin */
  font-family: "Aptos", sans-serif;
  letter-spacing: 0.2vw; /* Responsive letter spacing */
  width: fit-content;
  height: fit-content;
  text-shadow: 0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.2); /* Responsive shadow */
  box-shadow: 0.2vw 0.4vw 0.6vw rgba(0, 0, 0, 0.3); /* Responsive shadow */
  position: absolute;
  bottom: 7vw; /* Adjust bottom spacing */
  left: 6%; /* Adjust right spacing */
}

#about-btn:hover {
  background-color: rgba(47, 184, 229, 1);
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  #about-btn {
    font-size: 1.5vw; /* Responsive font size */
    background-color: rgba(47, 184, 229, 0.8);
    color: rgb(255, 255, 255);
    padding: 1vw 1vw; /* Responsive padding */
    border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%;
    margin: 2vh 2vw; /* Responsive margin */
    font-family: "Aptos", sans-serif;
    letter-spacing: 0.2vw; /* Responsive letter spacing */
    width: fit-content;
    height: fit-content;
    text-shadow: 0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.2); /* Responsive shadow */
    box-shadow: 0.2vw 0.4vw 0.6vw rgba(0, 0, 0, 0.3); /* Responsive shadow */
    position: absolute;
    bottom: 6vw; /* Adjust bottom spacing */
    left: 2%; /* Adjust right spacing */
  }

  #about-btn:hover {
    font-size: larger;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  #about-btn {
    position: absolute; /* Position the button absolutely */
    bottom: 2vh; /* Adjust bottom spacing as needed */
    left: 3%; /* Adjust left spacing as needed */
    z-index: 1; /* Ensure button is above the image */
    font-size: 1vh;
  }
}

@media screen and (max-width: 910px) {
  #about-btn {
    position: absolute; /* Position the button absolutely */
    bottom: 4vh; /* Adjust bottom spacing as needed */
    left: 4%; /* Adjust left spacing as needed */
    z-index: 1; /* Ensure button is above the image */
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .main-image {
    display: none;
  }

  .phone-image {
    display: block;
  }
}
@media screen and (width: 600px) {
  #about-btn {
    position: absolute; /* Position the button absolutely */
    bottom: 4vh; /* Adjust bottom spacing as needed */
    left: 10px; /* Adjust left spacing as needed */
    z-index: 1; /* Ensure button is above the image */
    font-size: 10px;
  }
}
