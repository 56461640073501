*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

/* index.css */
:root {
  --purple-colour: #a56eb0;
  --blue-colour: rgba(0, 184, 229);
  --darkpurple-colour: #7951a0;
  --purplelight-colour: rgba(165, 110, 176, 0.1);
  --grey-colour: #797575;
  --pink-colour: #fa85b4;

  --font-aptos: "Aptos", sans-serif;

  --container-width-lg: 80%;
  --container-width-md: 90%;
  --container-width-sm: 97%;
}

body {
  font-family: var(--font-aptos);
  color: black;
  line-height: 1;
  overflow-x: hidden;
  background: rgba(255, 255, 255, 0.35);
  position: relative;
  letter-spacing: 1px;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('../src/assets/images/image4.jpeg'); */
  opacity: 0.25;
  z-index: -1;
}

.container {
  width: var(--container-width-lg);
  max-width: 1920px;
  margin: auto;
  padding: 20px 20px;
  /* border: var(--blue-colour) 3px solid; */
  background-color: white;
  color: var(--grey-colour);
  border-radius: 20px;
}

@keyframes slideBorder {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Apply the animation to the section with the border */
section {
  background-color: rgb(255, 255, 255);
  border-top: 0px solid transparent; /* Initially, set the border to transparent */
  padding: 50px 100px;
  position: relative; /* Ensure relative positioning for pseudo-element */
}

@media screen and (max-width: 1240px) {
  svg.quote-icon {
    color: var(--grey-colour);
    font-size: 6rem;
  }

  section {
    padding: 50px 25px;
  }
}

/* Create a pseudo-element to represent the animated border */
section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  /* background-color: var(--blue-colour);  */
  animation: slideBorder 3s linear forwards; /* Apply the animation */
  animation-delay: 0.5s; /* Delay the animation for half a second */
  z-index: 1; /* Ensure the border is above the section content */
}

section h1 {
  font-size: 45px;
  color: var(--purple-colour);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  margin-top: 0.5rem;
  overflow: hidden;
  font-family: var(--font-aptos);
}

.active {
  border: 5px solid var(--pink-colour);
}

h1 {
  font-size: 45px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  color: var(--purple-colour);
  text-decoration: underline;
  font-weight: 100;
  font-family: var(--font-aptos);
}

h2 {
  font-size: 30px;
  margin-bottom: 2rem;
  color: var(--purple-colour);
  margin-top: 2rem;
  font-weight: 100;
  font-family: var(--font-aptos);
}

h3 {
  font-size: 25px;
  margin-bottom: 2rem;
  color: var(--grey-colour);
  margin-top: 2rem;
  text-align: center;
  font-weight: 100;
  font-family: var(--font-aptos);
}

a {
  /* font-size: 20px; */
  margin-bottom: 1rem;
  color: white;
}

p {
  font-size: 20px;
  margin-bottom: 1rem;
  color: var(--grey-colour);
  font-weight: 100;
  text-align: justify;
  text-align-last: center; /* Align last line to center */
  margin: 0 5%;
}

img {
  /* display: block; */
  object-fit: cover;
  /* max-width: 100%; */
}

.btn-small {
  font-size: 20px;
  background-color: rgba(0, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 8px 20px;
  /* border-radius: 62% 38% 71% 29% / 26% 46% 54% 74%; */
  /* border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%; */
  border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
  margin: 20px;
  font-family: "Aptos", sans-serif;
  letter-spacing: 0.8px;
  width: fit-content;
  height: fit-content;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.btn-small:hover {
  background-color: var(--blue-colour);
  color: white;
  transition: ease-in-out 0.5s;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 20px;
}

.btn-medium {
  font-size: 30px;
  background-color: rgba(0, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 10px 23px;
  /* border-radius: 62% 38% 71% 29% / 26% 46% 54% 74%; */
  border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%;
  margin: 20px;
  font-family: "Aptos", sans-serif;
  letter-spacing: 0.8px;
  width: fit-content;
  height: fit-content;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.btn-medium:hover {
  background-color: var(--blue-colour);
  color: white;
  transition: ease-in-out 0.5s;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 40px;
}

.btn-large {
  font-size: 30px;
  background-color: rgba(47, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 15px 20px;
  /* border-radius: 62% 38% 71% 29% / 26% 46% 54% 74%; */
  /* border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%; */
  border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
  margin: 40px;
  font-family: "Aptos", sans-serif;
  letter-spacing: 2px;
  width: fit-content;
  height: fit-content;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.btn-large:hover {
  background-color: var(--blue-colour);
  color: white;
  transition: ease-in-out 0.5s;
  padding: 20px 35px;
  cursor: pointer;
  font-size: 40px;
}

.btn-cool {
  font-size: 25px;
  background-color: rgba(0, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 10px 15px;
  /* border-radius: 62% 38% 71% 29% / 26% 46% 54% 74%; */
  border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
  margin: 10px;
  font-family: "Aptos", sans-serif;
  letter-spacing: 2px;
  width: fit-content;
  height: fit-content;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.btn-cool:hover {
  background-color: var(--blue-colour);
  color: white;
  transition: ease-in-out 0.5s;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.6vw;
}

.btn-xlcool {
  font-size: 25px;
  background-color: rgba(0, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 10px 15px;
  /* border-radius: 62% 38% 71% 29% / 26% 46% 54% 74%; */
  border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
  margin: 10px;
  font-family: "Aptos", sans-serif;
  letter-spacing: 2px;
  width: fit-content;
  height: fit-content;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.btn-xlcool:hover {
  background-color: var(--blue-colour);
  color: white;
  transition: ease-in-out 0.5s;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.6vw;
}

.btn-homepage {
  font-size: 2vw; /* Responsive font size */
  background-color: rgba(47, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 2vw 4vw; /* Responsive padding */
  border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%;
  margin: 2vh 2vw; /* Responsive margin */
  font-family: "Aptos", sans-serif;
  letter-spacing: 0.2vw; /* Responsive letter spacing */
  width: fit-content;
  height: fit-content;
  text-shadow: 0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.2); /* Responsive shadow */
  box-shadow: 0.2vw 0.4vw 0.6vw rgba(0, 0, 0, 0.3); /* Responsive shadow */
  cursor: pointer;
}

.btn-homepage:hover {
  background-color: rgba(47, 184, 229, 1);
  transition: ease-in-out 0.5s;
}

@media screen and (max-width: 1240px) {
  .btn-xlcool {
    font-size: 2.2vw; /* Responsive font size */
    background-color: rgba(0, 184, 229, 0.8);
    color: rgb(255, 255, 255);
    padding: 2vw 3vw; /* Responsive padding */
    border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
    margin: 2vh; /* Responsive margin */
    letter-spacing: 0.2vw; /* Responsive letter spacing */
    width: fit-content;
    height: fit-content;
    text-shadow: 0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.2); /* Responsive shadow */
    box-shadow: 0.2vw 0.4vw 0.6vw rgba(0, 0, 0, 0.3); /* Responsive shadow */
    text-align: center;
  }

  .btn-xlcool:hover {
    background-color: var(--blue-colour);
    color: white;
    transition: ease-in-out 0.5s;
    padding: 2vw 3vw; /* Responsive padding */
    cursor: pointer;
    font-size: 2vw; /* Responsive font size on hover */
  }
}

@media screen and (max-width: 768px) {
  .btn-xlcool {
    font-size: 1.8vw; /* Adjust font size for smaller screens */
    padding: 1vw 2vw; /* Adjust padding for smaller screens */
    margin: 4vh; /* Adjust margin for smaller screens */
    letter-spacing: 0.3vw; /* Adjust letter spacing for smaller screens */
    text-shadow: 0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.2); /* Adjust shadow for smaller screens */
    box-shadow: 0.3vw 0.5vw 0.7vw rgba(0, 0, 0, 0.3); /* Adjust shadow for smaller screens */
  }

  .btn-xlcool:hover {
    font-size: 6vw; /* Adjust font size for smaller screens on hover */
    padding: 3.5vw 4.5vw; /* Adjust padding for smaller screens on hover */
  }
}

@media screen and (max-width: 480px) {
  .btn-xlcool {
    font-size: 7vw; /* Adjust font size for even smaller screens */
    padding: 4vw 5vw; /* Adjust padding for even smaller screens */
    margin: 4vh; /* Adjust margin for even smaller screens */
    letter-spacing: 0.4vw; /* Adjust letter spacing for even smaller screens */
    text-shadow: 0.3vw 0.3vw 0.4vw rgba(0, 0, 0, 0.2); /* Adjust shadow for even smaller screens */
    box-shadow: 0.4vw 0.6vw 0.8vw rgba(0, 0, 0, 0.3); /* Adjust shadow for even smaller screens */
  }

  .btn-xlcool:hover {
    font-size: 8vw; /* Adjust font size for even smaller screens on hover */
    padding: 4.5vw 5.5vw; /* Adjust padding for even smaller screens on hover */
  }
}

.btn-xlarge {
  font-size: 25px;
  background-color: rgba(0, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 15px 20px;
  /* border-radius: 62% 38% 71% 29% / 26% 46% 54% 74%; */
  /* border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%; */
  border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
  margin: 20px;
  font-family: "Aptos", sans-serif;
  letter-spacing: 2px;
  width: fit-content;
  height: fit-content;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.btn-xlarge:hover {
  background-color: var(--blue-colour);
  color: white;
  transition: ease-in-out 0.5s;
  padding: 20px 30px;
  cursor: pointer;
  font-size: 22px;
}

.btn-standard {
  font-size: 20px;
  background-color: var(--blue-colour);
  border: 3px solid var(--grey-colour);
  color: rgb(94, 94, 94);
  padding: 15px 25px;
  border-radius: 20px;
  margin: 20px;
  font-family: "Aptos", sans-serif;
  letter-spacing: 2px;
  width: fit-content;
  height: fit-content;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.btn-standard:hover {
  background-color: var(--blue-colour);
  color: white;
  transition: ease-in-out 0.25s;
  padding: 18px 27px;
  cursor: pointer;
  font-size: 22px;
}

@media screen and (max-width: 1440px) {
  .container {
    width: var(--container-width-md);
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-sm);
  }
}
