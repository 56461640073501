.school-work p {
  text-align: center;
  font-size: 40px;
  letter-spacing: 0px;
  margin: 0 15%;
}

.school-purple {
  background-color: var(--purplelight-colour);
}

.buttons-school {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.school-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 50%;
  height: 50%;
  object-fit: scale-down;
}

#coaching-page p {
  text-align: center;
  font-size: 25px;
  letter-spacing: 0px;
  padding-top: 20px;
  padding-bottom: 30px;
}

.speech-bubble-container-coaching {
  position: relative;
  width: 828px;
  height: 464px;
  background-image: url("../../images//Illustrations/SpeechBubble.png"); /* Path to your speech bubble image */
  background-size: cover;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.speech-bubble-text-talk {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--blue-colour);
  font: var(font-medium);
  font-size: 27px;
  max-width: 90%;
  font-style: italic;
  letter-spacing: 1.5;
}

.h1-SchoolWellbeing {
  display: none;
}

@media screen and (max-width: 1440px) {
  .speech-bubble-container-coaching {
    background-image: none;
    margin-bottom: 0px;
  }
  .speech-bubble-text-talk {
    font-size: 35px;
  }
}

@media screen and (max-width: 1024px) {
  .image {
    width: 75%;
    height: 100%;
  }

  #coaching-page p {
    font-size: 35px;
  }
}
