/* #about-btn {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;
  margin-bottom: 50px;
} */

section {
  background-color: white;
}

.hidden {
  opacity: 0;
  transition: opacity 1s;
}

.show {
  opacity: 1;
}
