.contact__option {
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
}

.contact__option:hover {
  background: transparent;
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

#contactname {
  display: flex;
  text-align: center;
  font-size: 30px;
}

.person_img img {
  border: 3px solid var(--purple-colour);
  box-shadow: 1 1 1 0.5px grey;
}
