.container__book {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.BookPage__left {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.BookPageHeader {
  font-size: 3vw;
  color: var(--purple-colour);
  font-weight: 400;
  font-family: var(--font-aptos);
  text-decoration: none;
  /* margin-bottom: 7rem; */
}

.btn-BuyNowBookPage {
  z-index: 1;
  font-size: 1.5vw;
  background-color: rgba(47, 184, 229, 0.8);
  color: rgb(255, 255, 255);
  padding: 1vw 1vw;
  /* border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%; */
  border-radius: 84% 16% 87% 13% / 13% 83% 17% 87%;
  margin: 0vh 2vw;
  font-family: "Aptos", sans-serif;
  letter-spacing: 0.2vw;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-shadow: 0.1vw 0.1vw 0.2vw rgba(0, 0, 0, 0.2);
  box-shadow: 0.2vw 0.4vw 0.6vw rgba(0, 0, 0, 0.3);
}

.btn-BuyNowBookPage:hover {
  background-color: var(--blue-colour);
  transition: ease-in-out 0.5s;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.6vw;
}

.BookPage__left p {
  font-size: 25px;
  font-family: var(--font-aptos);
  margin-bottom: 2rem;
}

.snake-border {
  max-width: 65%;
  height: auto;
}

@media screen and (max-width: 1042px) {
  .BookPageHeader {
    font-size: 5vw;
  }

  .btn-BuyNowBookPage {
    font-size: 3vw;
    padding: 2vw 2vw;
  }

  .BookPage__left p {
    font-size: 2vw;
    font-family: var(--font-aptos);
    margin-bottom: 2rem;
  }
}
