#parent-charity-section {
  background-color: var(--purplelight-colour);
}

.charity-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.active {
  border: 5px solid var(--pink-colour);
}

.h1-parentcharity {
  margin-top: 6rem;
}

.speech-bubble-container-charity {
  position: relative;
  width: 1450px;
  height: 800px;
  background-image: url("../../images//Illustrations/SpeechBubble.png"); /* Path to your speech bubble image */
  background-size: cover;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.speech-bubble-text-charity {
  position: absolute;
  top: 35%;
  left: 47%;
  transform: translate(-50%, -50%);
  color: var(--blue-colour);
  font-family: "Skippy Sharp W00 Reg";
  font-size: 50px;
  max-width: 90%;
  font-style: italic;
  letter-spacing: 1.5;
}

.bar {
  background-color: white;
  margin-left: -100px;
  margin-right: -100px;
}

/* Media queries */

@media screen and (max-width: 1400px) {
  .speech-bubble-text-charity {
    font-size: 40px;
  }

  .speech-bubble-container-charity {
    background-image: none;
  }
}

@media screen and (max-width: 1024px) {
  #make-smaller-btn {
    font-size: 32px;
    background-color: rgba(47, 184, 229, 0.8);
    color: rgb(255, 255, 255);
    padding: 20px 20px;
    /* border-radius: 62% 38% 71% 29% / 26% 46% 54% 74%; */
    border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%;
    margin: 15px;
    font-family: "Aptos";
    letter-spacing: 0px;
    width: fit-content;
    height: fit-content;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  }

  .charity-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -100px;
    margin-right: -100px;
  }

  .h1-parentcharity {
    margin-top: 3rem;
  }

  .parent-purple {
    padding: 50px 50px;
  }
}
