#book-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lunch-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.active {
  border: 5px solid var(--pink-colour);
}

.feedback {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text inside the container */
}

.h1-parentlunch {
  margin-top: 6rem;
}

.bar {
  background-color: white;
  margin-left: -100px;
  margin-right: -100px;
}

.speech-bubble-container-book {
  position: relative;
  width: 828px;
  height: 464px;
  background-image: url("../../images//Illustrations/SpeechBubble.png"); /* Path to your speech bubble image */
  background-size: cover;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.speech-bubble-text-book {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--blue-colour);
  font: var(font-medium);
  font-size: 27px;
  max-width: 90%;
  font-style: italic;
  letter-spacing: 1.5;
}

.h1-SchoolWellbeing {
  display: none;
}

@media screen and (max-width: 1440px) {
  .speech-bubble-container-book {
    background-image: none;
    margin-bottom: 0px;
  }
  .speech-bubble-text-talk {
    font-size: 35px;
  }
}

@media screen and (max-width: 1024px) {
  #make-smaller-btn {
    font-size: 32px;
    background-color: rgba(47, 184, 229, 0.8);
    color: rgb(255, 255, 255);
    padding: 20px 20px;
    /* border-radius: 62% 38% 71% 29% / 26% 46% 54% 74%; */
    border-radius: 97% 3% 95% 5% / 19% 55% 45% 81%;
    margin: 15px;
    font-family: "Aptos";
    letter-spacing: 0px;
    width: fit-content;
    height: fit-content;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  }

  .book-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -100px;
    margin-right: -100px;
  }

  .h1-parentbook {
    margin-top: 3rem;
  }

  .parent-purple {
    padding: 50px 50px;
  }
}
